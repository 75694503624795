<template>
  <ImageContainer class="img-section-header" :size="size">
    <div class="caption">
      <div
        class="container"
        :class="{
          'justify-content-end': textRight,
          'text-right': textRight,
        }"
      >
        <slot />
      </div>
    </div>
  </ImageContainer>
</template>

<script>
import ImageContainer from "@/components/ImageContainer.vue";

export default {
  props: {
    size: {
      type: Number,
      default: 600,
    },
    textRight: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImageContainer,
  },
};
</script>

<style></style>
