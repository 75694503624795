<template>
  <div>
    <PageHeader fileName="header-20">
      <h3 v-html="lang.title" />
    </PageHeader>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column">
            <h2 class="mb-4 text-tertiary" v-html="lang.section1.header" />
            <h4>{{ lang.section1.subheader }}</h4>
          </div>
          <div class="col-lg-6">
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ lang.section1.title }}
            </h6>
            <p>{{ lang.section1.paragraph1 }}</p>
            <p>{{ lang.section1.paragraph2 }}</p>
          </div>
        </div>
      </div>
    </section>

    <SectionHeader fileName="header-20">
      <h1 v-html="lang.why.header" />
    </SectionHeader>

    <section class="py-7 bg-dark">
      <div class="container">
        <div class="row gap-y-4 mb-8">
          <div
            class="col-md-6 col-lg-4"
            v-for="(reason, ri) of lang.why.reasons"
            :key="ri"
          >
            <div
              class="card card-outline-tertiary text-white h-100 d-flex flex-column justify-content-between py-3 px-4"
            >
              <div class="card-header minh-1">
                <h4>{{ reason.title }}</h4>
              </div>
              <div class="card-body minh-md-2">
                <p class="mb-0">{{ reason.desc }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8 text-center d-flex flex-column gap-3 mb-5">
            <h4 class="text-tertiary" v-html="lang.explorer.pretitle" />
            <h3 class="i">{{ lang.explorer.title }}</h3>
            <h4>{{ lang.explorer.subtitle }}</h4>
          </div>

          <div class="col-12">
            <div class="pathway-table mb-5">
              <div
                class="table-row"
                :class="`table-row-${row.cols.length}-cols`"
                v-for="(row, ri) of lang.explorer.table.rows"
                :key="ri"
              >
                <div
                  class="table-col"
                  :class="col.class"
                  v-for="(col, ci) of row.cols"
                  :key="ci"
                >
                  <h5 class="mb-4">{{ col.title }}</h5>
                  <div
                    class="list-item"
                    v-for="(item, i) of col.items"
                    :key="i"
                  >
                    <i class="fa fa-play text-tertiary" />
                    <div v-html="item" />
                  </div>
                  <p v-if="col.desc" v-html="col.desc" />
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center my-5">
              <a
                href="#form"
                class="btn text-center px-5 d-inline-block"
                :class="`btn-tertiary`"
              >
                {{ lang.explorer.cta }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <SectionHeader fileName="pathway_01">
      <h1 v-html="lang.preparation.header" />
    </SectionHeader>

    <section class="py-7 bg-dark">
      <div class="container">
        <div class="row gap-y-4 mb-5">
          <div class="col-lg-5">
            <h3 v-html="lang.preparation.row1.title" />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <h6>{{ lang.preparation.row1.subtitle }}</h6>
            <p>{{ lang.preparation.row1.desc }}</p>
          </div>
        </div>
        <div class="row gap-y-4 mb-5">
          <div class="col-lg-5">
            <h4 class="text-tertiary" v-html="lang.preparation.row2.title" />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <h6>{{ lang.preparation.row2.subtitle }}</h6>
            <p>{{ lang.preparation.row2.desc }}</p>
          </div>
        </div>

        <div class="d-flex justify-content-center mt-5">
          <a
            href="#form"
            class="btn text-center px-5 d-inline-block"
            :class="`btn-tertiary`"
          >
            {{ lang.explorer.cta }}
          </a>
        </div>
      </div>
    </section>

    <SectionHeader fileName="pathway_02">
      <h1 v-html="lang.journey.header" />
    </SectionHeader>

    <section class="py-7 bg-dark">
      <div class="container">
        <div
          class="row gap-y-4 mb-5"
          v-for="(section, i) of lang.journey.sections"
          :key="i"
        >
          <div class="col-lg-4">
            <h4 class="text-tertiary">{{ section.title }}</h4>
          </div>
          <div class="col-lg-6 offset-lg-2">
            <ConnectedList
              theme="tertiary"
              :items="section.items"
              :itemPB="50"
              :itemGap="0"
            />
          </div>
        </div>

        <div class="d-flex justify-content-center mt-5">
          <a
            href="#form"
            class="btn text-center px-5 d-inline-block"
            :class="`btn-tertiary`"
          >
            {{ lang.journey.cta }}
          </a>
        </div>
      </div>
    </section>

    <SectionHeader fileName="pathway_03">
      <h1 v-html="lang.university.header" />
    </SectionHeader>

    <section class="py-7 bg-dark">
      <div class="container">
        <div class="row gap-y-4">
          <div class="col-lg-5">
            <h3 v-html="lang.university.title" />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <p>{{ lang.university.desc1 }}</p>
          </div>
          <div class="col-lg-5">
            <h4 class="text-tertiary" v-html="lang.university.subtitle" />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <p v-html="lang.university.desc2" />
          </div>
          <div class="col-lg-5">
            <h4 class="text-tertiary" v-html="lang.university.subtitle2" />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <div v-html="lang.university.desc3" />
          </div>
        </div>
      </div>
    </section>

    <section class="py-7 bg-tertiary">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("footer.title") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card1.title')"
              :to="{ name: 'meet_our_team' }"
            >
              <small class="m-0"> {{ $lang("footer.card1.body") }} </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card2.title')"
              :to="{ name: 'methodology', hash: '#levels' }"
            >
              <small class="m-0"> {{ $lang("footer.card2.body") }} </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card3.title')"
              :to="{ name: 'extra_activities' }"
            >
              <small class="m-0"> {{ $lang("footer.card3.body") }} </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-17" theme="tertiary" id="form">
      {{ lang.contact_form.location }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import ConnectedList from "@/components/ConnectedList.vue";
import CardButton from "@/components/CardButton.vue";

export default {
  components: {
    PageHeader,
    ContactFormSection,
    SectionHeader,
    ConnectedList,
    CardButton,
  },
  computed: {
    lang() {
      return this.$lang("pathway", true) || {};
    },
  },
};
</script>

<style></style>
